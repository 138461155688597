// import { memo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'
import { x, useDown } from '@xstyled/styled-components'
import useHasMounted from '../../lib/useHasMounted'
// import deepCompare from '../../lib/deepCompare'

import Articles from '../components/Articles'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'
import { homePageView, setPageType } from '../../store/app'
import WingBanners from '../components/WingBanners'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import NoImage from '../../../assets/images/general/no-image.png'
import next from '../../../assets/images/icons/gallery-next.svg'

import HomePageTVBox from '../../templates/HomePageTVBox'
import HomePageNews from '../../templates/HomePageNews'
import HomePageCurrentAffairs from '../../templates/HomePageCurrentAffairs'
import HomePageBrandstories from '../../templates/HomePageBrandstories'
import HomePageVideos from '../../templates/HomePageVideos'
import HomePageSport from '../../templates/HomePageSport'
import HomePageEntertainment from '../../templates/HomePageEntertainment'
import HomePageBusiness from '../../templates/HomePageBusiness'
import HomePageLifestyle from '../../templates/HomePageLifestyle'
import MostReadWidget from '../components/MostReadWidget'
import TrendsBar from '../components/TrendsBar'
import NewspapersSlider from '../components/NewspapersSlider'
import StyledLink from './../components/ui/Link'
import { getVisitorId, getSessionId } from '../../store/article'
import Oovvuu, { ErrorBoundary as OovvuuErrorBoundary } from './../components/Oovvuu'
import iolPropertyBanner from '../../../assets/images/general/iol-property-banner.png'
import Membrana from '../components/Membrana'

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const HP = [
  [[1024, 0], [[300, 600], 'fluid']],
  [[640, 0], [[300, 600], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const mpuRHS = [
  [[1024, 0], [[300, 250], 'fluid']],
  [[640, 0], [[300, 250], [336, 280], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const NotFound = (props) => (
  <div>Oops! Not found</div>
)

const Home = (props) => {
  const downMd = useDown('md')
  const hasMounted = useHasMounted()
  setPageType('home')
  const clickSource = 'News'
  if (typeof window !== 'undefined') {
    const path = `/${process.env.RAZZLE_DFP_CODE}${props.adPath || (props.location.pathname === '/' ? '/homepage' : props.location.pathname)}`
    const descriptionUrl = `description_url=${encodeURIComponent(window.location.href)}`
    const custParams = 'cust_params=title%3d{mediainfo.name}%26description%3d{mediainfo.description}%26keywords%3d{mediainfo.tags}'
    window.oovvuuReplacementAdServerURL = ` https://pubads.g.doubleclick.net/gampad/ads?iu=${path}&sz=640x480&${custParams}&ciu_szs=300x250&gdfp_req=1&ad_rule=1&ad_type=video&output=vmap&unviewed_position_start=1&env=vp&impl=s&correlator=&${descriptionUrl}&vpmute=1&plcmt=1`
  }
  if (!hasMounted) {
    props.homePageView(props.location.pathname, props.visitorId, props.sessionId, props.app.firstRender)
  }
  return (
    <>
      <Helmet>
        {process.env.RAZZLE_ENABLE_MEBRANA && <script async='async' src='https://cdn.membrana.media/iol/ym.js' />}
      </Helmet>
      <x.main px={{ sm: '20px', wAdD: props.app.wallpaperActive ? '0px' : '200px' }} mt={{ sm: '96px', lg: props.app.wallpaperActive ? '0px' : '173px' }} w='100%' maxWidth={{ sm: '1260px', wAdD: props.app.wallpaperActive ? '1220px' : '1660px' }} mx='auto'>
        <Ad isConnected={props.isConnected} adPath={props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
        <WingBanners isConnected={props.isConnected} adPath={props.adPath} location={props.location} mappingLeft={Wing} mappingRight={Wing} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
        {/* TV Box Start */}
        <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
          <Articles {...props} noDiv isConnected section='tvbox' count={7} pageSize={7} noImage={NoImage} notFound={NotFound} component={HomePageTVBox} />
          <x.aside>
            {/* Tab List Start */}
            <MostReadWidget />
            {/* Tab List End */}
            <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
              <Ad isConnected={props.isConnected} slotId='MPU-topright' targeting={{ MPU: 'topright' }} collapseEmptyDiv className='advert' mapping={mpuRHS} {...FORMATS.mpu} />
            </x.div>
          </x.aside>
        </x.div>
        {/* TV Box End */}
        <TrendsBar />
        <x.div my={20} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Ad isConnected={props.isConnected} collapseEmptyDiv slotId='Leaderboard-middle' targeting={{ Leaderboard: 'middle' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
        </x.div>
        {/* Latest Videos Start */}
        {/* <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/video' visitorId={props.visitorId} sessionId={props.sessionId} section='homepage' source={clickSource}>Latest Videos</StyledLink></x.h3>
            {typeof window !== 'undefined' &&
              <x.div maxWidth={1220} w='100%' mx='auto'>
                <OovvuuErrorBoundary>
                  <Oovvuu uuid='baa6440f-1c98-4a50-965c-db75a4f946eb' />
                </OovvuuErrorBoundary>
              </x.div>}
          </x.div>
        </x.div> */}
        {/* Latest Videos End */}
        {/* Membrana start */}
        {process.env.RAZZLE_ENABLE_MEBRANA && <Membrana />}
        {/* Membrana End */}
        {/* Magic 828 Start */}
        {/* <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <x.iframe src='https://streaming.fabrik.fm/fabrik/echocast/player/live/player.html?id=3183fc5e-9da7-4399-a711-cade0ad8db13&amp;web_url=https://magic828live.co.za/&amp;matomo_id=107&amp;theme=magicdark&amp;web_name=Magic Radio-Live Stream' scrolling='no' width='100%' height='218' frameborder='0'><span display='inline-block' width='0px' overflow='hidden' lineheight='0' data-mce-type='bookmark' className='mce_SELRES_start'>﻿</span><a href=''>Live stream by Fabrik.fm</a></x.iframe>
        </x.div> */}
        {/* Magic 828 End */}
        {/* IOL Property Banner Start */}
        <x.div w='100%' maxWidth={1220} mx='auto' my={50} h={200} overflow='hidden' display='flex' justifyContent='center' alignItems='center' position='relative'>
          <x.a target='_blank' href='https://iolproperty.co.za'>
            <x.img backgroundRepeat='no-repeat' backgroundPosition='50%' backgroundSize={{ sm: 'contain', md: 'cover' }} src={iolPropertyBanner} />
          </x.a>
        </x.div>
        {/* IOL Property Banner End */}
        {/* News Start */}
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/news' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>News</StyledLink></x.h3>
            <StyledLink to='/news' position='absolute' top='50%' right='0' transform='translateY(-50%)' textTransform='uppercase' text='small' color={{ hover: 'red' }} display='flex' justifyContent='center' alignItems='center' flexDirection='row' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>More <x.img src={next} w='24px' h='24px' /></StyledLink>
          </x.div>
          <Articles
            {...props} noDiv section='news' count={7} pageSize={7} noImage={NoImage} notFound={NotFound} component={({ articles, section, noImage, isConnected, withReadCount }) => (
              <HomePageNews articles={articles} section={section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
            )}
          />
        </x.div>
        {/* News End */}
        <x.div maxWidth={1220} w='100%' mx='auto' py={{ sm: '20px', lg: '50px' }}>
          <NewspapersSlider visitorId={props.visitorId} sessionId={props.sessionId} section='news' />
        </x.div>
        {/* Current Affairs Start */}
        <x.div maxWidth={1220} w='100%' mx='auto'>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/news/politics' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>Politics</StyledLink></x.h3>
            <StyledLink to='/news/politics' position='absolute' top='50%' right='0' transform='translateY(-50%)' textTransform='uppercase' text='small' color={{ hover: 'red' }} display='flex' justifyContent='center' alignItems='center' flexDirection='row' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>More <x.img src={next} w='24px' h='24px' /></StyledLink>
          </x.div>
          <Articles {...props} noDiv section='news/politics' count={9} pageSize={9} noImage={NoImage} notFound={NotFound} component={(p) => <HomePageCurrentAffairs {...p} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />} />
        </x.div>
        {/* Current Affairs End */}
        {/* Sport Start */}
        <x.div w='100%' maxWidth={1220} mx='auto' mt={50}>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/sport' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>Sport</StyledLink></x.h3>
            <StyledLink to='/sport' position='absolute' top='50%' right='0' transform='translateY(-50%)' textTransform='uppercase' text='small' color={{ hover: 'red' }} display='flex' justifyContent='center' alignItems='center' flexDirection='row' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>More <x.img src={next} w='24px' h='24px' /></StyledLink>
          </x.div>
          <Articles {...props} section='sport' count={6} pageSize={6} noImage={NoImage} notFound={NotFound} component={(p) => <HomePageSport {...p} mapping={HP} visitorId={props.visitorId} sessionId={props.sessionId} />} />
        </x.div>
        {/* Sport End */}
        {/* Lifestyle Start */}
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/lifestyle' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>Lifestyle</StyledLink></x.h3>
            <StyledLink to='/lifestyle' position='absolute' top='50%' right='0' transform='translateY(-50%)' textTransform='uppercase' text='small' color={{ hover: 'red' }} display='flex' justifyContent='center' alignItems='center' flexDirection='row' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>More <x.img src={next} w='24px' h='24px' /></StyledLink>
          </x.div>
          <Articles {...props} section='lifestyle' count={4} pageSize={4} noImage={NoImage} notFound={NotFound} component={HomePageLifestyle} />
        </x.div>
        {/* Lifestyle End */}
        {/* Entertainment Start */}
        <x.div w='100%' maxWidth={1220} mx='auto' mt={50}>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/entertainment' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>Entertainment</StyledLink></x.h3>
            <StyledLink to='/entertainment' position='absolute' top='50%' right='0' transform='translateY(-50%)' textTransform='uppercase' text='small' color={{ hover: 'red' }} display='flex' justifyContent='center' alignItems='center' flexDirection='row' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>More <x.img src={next} w='24px' h='24px' /></StyledLink>
          </x.div>
          <Articles {...props} section='entertainment' count={8} pageSize={8} noImage={NoImage} notFound={NotFound} component={HomePageEntertainment} />
        </x.div>
        {/* Entertainment End */}
        <x.div my={20} w='100%' maxWidth={1220} mx='auto' textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Ad isConnected={props.isConnected} collapseEmptyDiv slotId='Leaderboard-3' targeting={{ Leaderboard: '3' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
        </x.div>
        {/* Business Start */}
        <x.div w='100%' maxWidth={1220} mx='auto'>
          <x.div position='relative' w='100%'>
            <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}><StyledLink to='/business' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>Business</StyledLink></x.h3>
            <StyledLink to='/business' position='absolute' top='50%' right='0' transform='translateY(-50%)' textTransform='uppercase' text='small' color={{ hover: 'red' }} display='flex' justifyContent='center' alignItems='center' flexDirection='row' visitorId={props.visitorId} sessionId={props.sessionId} section='news' source={clickSource}>More <x.img src={next} w='24px' h='24px' /></StyledLink>
          </x.div>
          <Articles {...props} section='business' count={11} pageSize={11} noImage={NoImage} notFound={NotFound} component={(p) => <HomePageBusiness {...p} mapping={HP} visitorId={props.visitorId} sessionId={props.sessionId} />} />
        </x.div>
        {/* Business End */}
        {/* Property Start */}
        {/* <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Property (future)</x.h3>
          <Articles
            {...props} section='lifestyle/home-garden/home' count={4} pageSize={4} noImage={NoImage} notFound={NotFound} component={({ articles, section, noImage, isConnected, withReadCount }) => (
              <ArticleDeck articles={articles} section={section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} />
            )}
          />
        </x.div> */}
        {/* Property End */}
        {hasMounted && !downMd &&
          <x.div mt={20} mb={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <Ad isConnected={props.isConnected} collapseEmptyDiv slotId='Leaderboard-bot' targeting={{ Leaderboard: 'bot' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
          </x.div>}
        {/* Partnered Start */}
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <x.h3 text='h3' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Partnered</x.h3>
          <Articles {...props} section='front-page-sponsored-or-partnered' count={4} pageSize={4} noImage={NoImage} notFound={NotFound} component={HomePageBrandstories} />
        </x.div>
        {/* Partnered End */}
        <x.div maxWidth={1220} w='100%' mx='auto' my={50} />
      </x.main>
    </>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchArticles, fetchMoreArticles, homePageView }, dispatch)

export default withRouter(connect((state) => ({ app: state.app, articles: state.articles, visitorId: getVisitorId(state), sessionId: getSessionId(state) }), mapDispatchToProps)(Home))
// export default memo(withRouter(connect((state) => ({ articles: state.articles, visitorId: getVisitorId(state) }), mapDispatchToProps)(Home)), deepCompare)
